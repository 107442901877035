import axios, { AxiosError } from 'axios';
import type { Notification } from './localstorage/notification.js';
import { setNotification } from './localstorage/notification.js';
import { deleteData, getData } from './storage.js';
import { errorToast } from './toast.js';

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

let Synctimer: NodeJS.Timeout;

const signoutUser = async (search?: boolean, error = 'Login expired - logg inn på nytt', type: Notification['type'] = 'warning') => {
  await deleteData('bearertime');
  await deleteData('bearer');
  setNotification({ text: error, type });
  document.cookie = 'loggedin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
  window.location.href = `${window.location.origin }/${search ? window.location.search : ''}`;
  window.location.reload();
};

function abortFetchInSeconds(timeout: number): AbortSignal {
  if (window.AbortController === undefined) {
    throw new Error('Accesslevel denied');
  }
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeout * 1000);

  return abortController.signal;
}

export async function Backend(
  endpoint: string,
  method: HTTPMethods,
  body?: string,
  form?: FormData,
  silent?: boolean,
): Promise<Response> {
  let options = {};
  const bearer = await getData('bearer');

  const params = new URLSearchParams(window.location.search);
  const paramKey = params.get('key');

  if (body) {
    options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearer}`,
        ...(paramKey ? { customkey: true } : {}),
      },
      signal: abortFetchInSeconds(59),
      responseType: 'stream',
      ...(method !== HTTPMethods.GET && { body }),
      // mode: 'cors',
    };
  } else {
    options = {
      method,
      headers: {
        // 'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${bearer}`,
        ...(paramKey ? { customkey: true } : {}),
      },
      body: form,
      signal: abortFetchInSeconds(59),
      responseType: 'stream',
      // mode: 'cors',
    };
  }

  params.delete('key');
  const paramsString = params.toString();
  const url = `${import.meta.env.VITE_API_URL ?? 'https://cppro.no'}/${ endpoint }${paramsString ? (`?${ paramsString}`) : ''}`;

  // Show the loading indicator with id Sender
  const sender = document.getElementById('Sender');
  if (sender && !silent) {
    clearTimeout(Synctimer);
    Synctimer = setTimeout(() => {
      sender.style.display = 'block';
    }, 200);
  }
  const bearertime = await getData('bearertime');
  if (bearertime) {
    const date = new Date(Number(bearertime)).getDate();
    if (new Date().getDate() !== date) {
      await signoutUser(true);
      return Promise.reject(new Error('Bearer expired'));
    }
  }
  if (!bearertime) {
    await signoutUser();
    return Promise.reject(new Error('Bearer expired'));
  }
  return Promise.resolve(
    await fetch(url, options).catch((error) => {
      console.error(error);
      errorToast(`Fikk ikke kontakt med server - prøv igjen senere${error.message ? `. Error: ${ error.message}` : ''}`);
      return Promise.reject(error);
    }).then((response) => {
      if (!response.ok) {
        response.text().then((respBody) => {
          errorToast('Server feil - prøv igjen senere');
          console.error(respBody);
          if (response.status === 401 && (respBody === 'Token expired' || respBody === 'User not found')) {
            signoutUser(false, respBody === 'User not found' ? 'Bruker ikke funnet' : 'Login expired - logg inn på nytt');
          }
        }).catch(() => {
          errorToast('Server feil - prøv igjen senere');
        });
      }

      return response;
    }).finally(() => {
      const sender2 = document.getElementById('Sender');
      if (sender2) {
        clearTimeout(Synctimer);
        sender2.style.display = 'none';
      }
    }),
  );
}

export async function ActivityHelper<T>(
  endpoint: string,
  method: HTTPMethods,
  body?: unknown,
  override?: boolean,
): Promise<T | AxiosError> {
  const bearer = await getData('bearer');

  const url = `${import.meta.env.VITE_ACTIVITYHELPER_URL ?? 'https://activityhelper.cppro.no'}/${endpoint}`;

  try {
    const response = await axios.request<T>({
      url,
      method,
      data: body,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    });

    if (response.status !== 200) {
      errorToast(`Server feil: ${response.data}`, override);
      console.error(response.data);
      if (response.status === 401 && (response.data === 'Token expired' || response.data === 'User not found')) {
        signoutUser(false, response.data === 'User not found' ? 'Bruker ikke funnet' : 'Login expired - logg inn på nytt');
      }
      return new AxiosError(JSON.stringify(response.data));
    }

    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    console.error(error);
    errorToast(`Server feil: ${error.response?.data ?? error.message ?? ''}`, override);
    return error;
  }
}
